<template>
  <div id="style-guide">
    <h1>ParkingCrew Styles</h1>
    <p>Auf diesen Seiten werden die genutzten Vuetify Elemente ohne Anpassungen des Styles dargestellt.</p>

    <p>
      Das Ziel ist es, dass die Element und Beispiele, nach dem Aufräumen des CSS <strong>ohne custom css</strong> auf Desktop und Mobile gut aussehen
      und zu den UX Stylevorgaben passen.
    </p>

    <ul>
      <li><a href="/#/style/atoms">Atome</a></li>
      <li><a href="/#/style/vcard-with-data-table">Beispiel: VCard with data table</a></li>
      <li><a href="/#/style/vcard-with-form">Beispiel: VCard with form</a></li>
      <li><a href="/#/style/vcard-with-two-columns-form">Beispiel: VCard with two columns on large screen</a></li>
    </ul>
  </div>
</template>

<script>
export default {}
</script>
